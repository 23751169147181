import Origin from '../origin/Origin';
import LandingCore from './core/LandingCore';

const Landing = () => {
  return (
    <Origin Core={LandingCore} />
  );
};

export default Landing;
