import Header from '../../common/Header';
import ThreatTimelineBodyBox from '../../common/ThreatTimelineBodyBox';
import HeadTitleBox from '../../common/HeadTitleBox';
import Speech from '../../common/Speech';
import ListSpeech from '../../common/ListSpeech';
import SecondTitleBox from '../../common/SecondTitleBox';
import KnowledgeListBox from '../../common/KnowledgeListBox';
import ImageFigure from '../../common/ImageFigure';
import ThreatCard from '../../threat/comps/casino/ThreatCard';

import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItem from '@mui/material/ListItem';
import CircleIcon from '@mui/icons-material/Circle';

const LandingCore = () => {
  const [focusedThreat, setFocusedThreat] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(focusedThreat.length === 0);
  }, [focusedThreat]);

  return (
    <>
      <Header>
        <Typography
          style={{ fontSize: "0.8rem", fontWeight: "bold" }}
        >
          ISL(Information Security Lab.)
        </Typography>
        <Typography
          style={{ fontSize: "1.2rem", fontWeight: "bold" }}
        >
          東京電機大学 情報セキュリティ研究室
        </Typography>
      </Header>
      <ThreatTimelineBodyBox setFocusedThreat={setFocusedThreat}>
        <Box
          mb={6}
        >
          <Box mb={6}>
            <HeadTitleBox>
              研究室活動
            </HeadTitleBox>
            <Speech>
              東京電機大学 情報セキュリティ研究室では、授業の一環として、研究室の学生が学外の方との交流を通していろいろな分野を知る、\
              質疑応答などを通してコミュニケーションの大切さを知るなどに取り組んでいます。
            </Speech>
            <KnowledgeListBox
              to="https://blog.isl.im.dendai.ac.jp/"
              sx={{ marginTop: 2 }}
            >
              研究室活動へのリンクはこちら
            </KnowledgeListBox>
          </Box>
          <Box>
            <HeadTitleBox>
              サイバー攻撃抑止の実証実験
            </HeadTitleBox>
            <Box mb={3}>
              <Speech>
                この研究室Webサイトでは「研究室Webサイトへのサイバー攻撃を、研究室Webサイト自らが見える化する」ことによるサイバー攻撃抑止の実証実験を行っています。
              </Speech>
            </Box>
            <Box display="flex" gap={2} flexWrap="wrap" mb={3}>
              <Box sx={{ flexShrink: 0 }}>
                {isLoading ? (
                  <Skeleton variant="rectangular" width="260px" height="420px"  />
                ) : (
                  <ThreatCard threat={focusedThreat} />
                )}
              </Box>
              <Box minWidth="200px" maxWidth="calc(100% - 260px - 16px)">
                <List>
                  <ListItem>
                    <ListSpeech>「研究室Webへの攻撃現況」には、実際に見える化している攻撃のなかから最近の10件を載せています</ListSpeech>
                  </ListItem>
                  <ListItem>
                    <ListSpeech>「研究室Webへの攻撃現況」で攻撃情報を選択するとカードを更新できます</ListSpeech>
                  </ListItem>
                  <ListItem>
                    <ListSpeech>カードで確認できる攻撃情報:</ListSpeech>
                  </ListItem>
                  <List sx={{paddingTop: 0}}>
                    <ListItem sx={{ paddingLeft: 4, paddingTop: 0}}><ListSpeech>攻撃日時</ListSpeech></ListItem>
                    <ListItem sx={{ paddingLeft: 4, paddingTop: 0}}><ListSpeech>攻撃元の機器のIPアドレス (リンクから詳細な攻撃元の機器の情報を取得可能)</ListSpeech></ListItem>
                    <ListItem sx={{ paddingLeft: 4, paddingTop: 0}}><ListSpeech>攻撃手法</ListSpeech></ListItem>
                    <ListItem sx={{ paddingLeft: 4, paddingTop: 0}}><ListSpeech>攻撃内容</ListSpeech></ListItem>
                  </List>
                </List>
              </Box>
            </Box>
            <Speech>
              このWebサイトの役割のひとつに、「リアルな攻撃の現状の認知を広げ、攻撃情報の活用を活性化する」ことがあります。\
              より多くの方が攻撃への意識をもつこと、攻撃元の情報を認知すること、そして攻撃の対策を強化することが、サイバー攻撃抑止につながっていきます。
            </Speech>
            <Box mt={3}>
              <KnowledgeListBox
                to="/threat"
                inner={true}
              >
                実証実験の概要
              </KnowledgeListBox>
              <KnowledgeListBox
                to="/threat/future"
                inner={true}
              >
                実証実験の構想
              </KnowledgeListBox>
              <KnowledgeListBox
                to="/threat/asset"
                inner={true}
              >
                サイバー攻撃の見える化
              </KnowledgeListBox>
            </Box>
          </Box>
        </Box>
      </ThreatTimelineBodyBox>
    </>
  );
};

export default LandingCore;
