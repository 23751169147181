import Header from '../../common/Header';
import BodyBox from '../../common/BodyBox';
import HeadTitleBox from '../../common/HeadTitleBox';
import Speech from '../../common/Speech';
import SecondTitleBox from '../../common/SecondTitleBox';
import KnowledgeListBox from '../../common/KnowledgeListBox';
import ImageFigure from '../../common/ImageFigure';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const FutureCore = () => {
  return (
    <>
      <Header>
        <Typography style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
          実証実験の構想
        </Typography>
      </Header>
      <BodyBox>
        <Box mb={6}>
          <HeadTitleBox>
            実証実験の構想
          </HeadTitleBox>
          <Box mb={4}>
            <Speech mb={1}>
              私たちはサイバー攻撃から守る側が強く有利になるために下図のモデルが必要だと考え、その有効性や在り方を実証実験で検証しています。\
              このモデルは、主に2つの特長がサイバー攻撃の抑止につながり、結果的に守る側が有利になるアプローチです。
            </Speech>
            <Speech mb={1}>
              1点目は、サイバー攻撃の攻撃元の機器を特定し、さらに攻撃者を追跡することです。\
              特に、攻撃者に悪用されている機器を早期に特定し救済すること、\
              またそこを起点に攻撃者を追跡していくことで、攻撃の抑止につなげます。
            </Speech>
            <Speech>
              2点目は、サイバー攻撃から守る側が協力して1つの組織が受けた攻撃を集団で対策し発信することです。\
              守る側が特定したサイバー攻撃を協力組織内で対策し、それ以降のサイバー攻撃を広域で失敗させること、\
              またそれを発信し攻撃者がサイバー攻撃をした場合のデメリットを理解することで、サイバー攻撃対策のレベルを上げるとともに、攻撃の抑止につなげます。
            </Speech>
          </Box>
          <ImageFigure src='/exs/ex1_2.jpg' />
        </Box>
        <Box mb={6}>
          <HeadTitleBox>
            サイバー攻撃を抑止するための重点
          </HeadTitleBox>
          <Box mb={4}>
            <Speech mb={4}>
              上記のモデルをもとにサイバー攻撃の抑止力を上げていくためには、「サイバー攻撃を特定し公開する機能の向上」と「協力組織の広がり」が重要になります。
            </Speech>
            <Box mb={4}>
              <SecondTitleBox>
                サイバー攻撃を特定し公開する機能の向上
              </SecondTitleBox>
              <Speech>
                サイバー攻撃を特定し公開する機能を追究していくことで、個の力として抑止する力が強くなります。\
                具体的には「特定するサイバー攻撃の種類の多様化」や「公開するサイバー攻撃の帰属情報や、その公開方法の洗練」、「協力組織間でのサイバー攻撃の情報共有の方法の策定や改善」を通して、サイバー攻撃に対抗する力を高めていきます。
              </Speech>
            </Box>
            <Box mb={4}>
              <SecondTitleBox>
                協力組織の広がり
              </SecondTitleBox>
              <Speech>
                上項で追究した機能を幅広い協力組織で実装し、さらに集団で対策することで、集団の力によって抑止する力がより強くなります。\
                具体的には「より多くの組織でサイバー攻撃を特定・公開し、早期に対策していく」「機器を差し止める組織や、攻撃者を追跡する組織と連携していく」ことで、攻撃者がサイバー攻撃をした場合のデメリットをより大きくしていきます。
              </Speech>
            </Box>
          </Box>
          <ImageFigure src='/exs/ex2_2.jpg' />
        </Box>
        <Box mb={6}>
          <HeadTitleBox>
            サイバー攻撃の抑止を成立させる要件
          </HeadTitleBox>
          <Box mb={2}>
            <Speech>
              上記の重点をより現実的にしていくために、既存の「抑止の成立要件」をもとにWebサイトを活用したサイバー攻撃抑止を試みます。
            </Speech>
            <Speech>
              既存の抑止の成立要件では「帰属」「伝達」「信頼性」が満たされることで、抑止が成立するとされています。\
              そこで、私たちはWebサイトを活用することで「帰属」「伝達」「信頼性」をより満たすことができると考え、私たちのWebサイトが受けたサイバー攻撃をそのWebサイト自身で公開しています。\
              本実証実験では、Webサイトの在り方(特定手法、公開方法、協力組織間の共有方法)や協力体制の在り方を検証しています。
            </Speech>
          </Box>
          <ImageFigure src='/exs/ex3_2.jpg' mb={2} />
          <KnowledgeListBox
            to='https://dl.ndl.go.jp/view/download/digidepo_9104304_po_20140210.pdf?contentNo=1'
          >
            栗田真広, サイバー攻撃に対する「抑止」の現状 -米国の安全保障政策の事例から-
          </KnowledgeListBox>
        </Box>
        <Box mb={6}>
          <HeadTitleBox>
            サイバー攻撃の抑止を目的としたWebサイトの方針と役割
          </HeadTitleBox>
          <Box mb={2}>
            <Speech mb={1}>
              上記の「サイバー攻撃を抑止するための重点」と「サイバー攻撃の抑止を成立させる要件」から、Webサイトの役割を次の2つに定めています。
            </Speech>
            <Speech mb={1}>
              1点目は「攻撃者に私たちの意思と能力を見える化する」ことです。\
              攻撃者が、もしサイバー攻撃をした場合、その攻撃が特定され、広域でサイバー攻撃が失敗するとともに、自分に追跡の手が伸びてくると理解し信じられるようにする役割です。\
              この役割によって、個の力と集団の力が攻撃者の「帰属」に迫ることを、攻撃者に「伝達」し「信頼性」を持たせることで抑止につなげます。
            </Speech>
            <Speech mb={1}>
              2点目は「協力組織に向けてサイバー攻撃を見える化する」です。\
              協力組織の1つがサイバー攻撃を受けたとき、その情報を共有することで、協力組織全体が未然にそのサイバー攻撃に対策できることに始め、攻撃元の機器の特定・救済や攻撃者の追跡につなげていく役割です。\
              この役割によって、集団の力を行使する実現性と説得力を持たせ、役割1の「帰属」「信頼性」をより高めることで、抑止につなげます。
            </Speech>
          </Box>
          <ImageFigure src='/exs/ex7_2.jpg' mb={2} />
        </Box>
        <Box mb={6}>
          <HeadTitleBox>
            サイバー攻撃の帰属情報等選出の方針
          </HeadTitleBox>
          <ImageFigure src='/exs/ex8.jpg' mb={2} />
        </Box>
        <Box mb={6}>
          <HeadTitleBox>
            サイバー攻撃の帰属情報等と協力組織間での活用目的
          </HeadTitleBox>
          <Box mb={2}>
            <Speech mb={1}>
              Webサイトでは、「抑止の成立要件」や「Webサイトの方針と役割」の観点からサイバー攻撃の「帰属」に関連する情報等を公開していきます。
            </Speech>
            <Speech mb={1}>
              現在は、「攻撃日時」から「攻撃元の機器に内在する脆弱性」までの8種類の情報を公開しています。
            </Speech>
          </Box>
          <ImageFigure src='/exs/ex9.jpg' mb={2} />
          <ImageFigure src='/exs/ex10.jpg' mb={2} />
          <ImageFigure src='/exs/ex11.jpg' mb={2} />
        </Box>
        <Box mb={6}>
          <HeadTitleBox>
            特定し公開するサイバー攻撃の種類
          </HeadTitleBox>
          <Box mb={4}>
            <Speech mb={1}>
              今回は「私たちのWebサイトが受けた攻撃を、Webサイト自身で公開する」として、まずは「Webサイトへの攻撃」に注目して攻撃の情報を公開していきます。\
              攻撃の検知には「IPA: 経済産業省のIT政策実施機関」のiLogScannerを活用し、抑止の成立要件の「伝達」や「信頼性」を満たすひとつの工夫としています。
            </Speech>
          </Box>
          <ImageFigure src='/exs/ex6_2.jpg' />
        </Box>
      </BodyBox>
    </>
  );
};

export default FutureCore;
