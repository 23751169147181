import ThreatTimeBranch from './ThreatTimeBranch';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

const ThreatTimeTree = ({ threats, setFocusedThreat }) => {
  var [dailyThreats, lastDate] = [{}, ''];
  threats.forEach((threat) => {
    const [date, _] = threat.access_clock.split('; ');
    if (!(date in dailyThreats)) {
      dailyThreats[date] = [];
      lastDate = date;
    }

    dailyThreats[date].push(threat);
  });

  return (
    <>
      <Typography fontSize="1.3rem" fontWeight="bold" pl={0.5} mb={1}>
        攻撃タイムライン
      </Typography>
      {Object.keys(dailyThreats).map((date) => (
        <Box pt={1} pb={1}>
          <ThreatTimeBranch date={date} threats={dailyThreats[date]} setFocusedThreat={setFocusedThreat} />
          {date !== lastDate ? (
            <Divider variant="middle" />
          ) : (
            <></>
          )}
        </Box>
      ))}
    </>
  );
};

export default ThreatTimeTree;
