import TitleBox from './TitleBox';

import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { alpha, useTheme } from '@mui/material/styles';

const KnowledgeListBox = (props) => {
  const { sx, style, to, inner=false } = props;
  const theme = useTheme();
  return (
    <Box
      sx={{ ...sx }}
      style={{ ...style }}
      mb={1.5}
    >
      <Button
        sx={{ padding: 0, width: "100%", textTransform: "none" }}
        style={{ justifyContent: "flex-start" }}
        component={ Link }
        to={ to }
        target={ inner ? '_self' : '_blank' }
        rel="noopener noreferrer"
      >
        <Box
          p={1.5}
          sx={{ width: "100%", borderRadius: 2 }}
          style={{
            backgroundColor: alpha(theme.palette.sky.light, 0.15),
            "&:hover": {
              backgroundColor: alpha(theme.palette.sky.light, 0.25)
            }
          }}
        >
          <TitleBox
            Icon={ <TextSnippetIcon sx={{ color: "sky.main" }} style={{ fontSize: "1.3rem" }} /> }
          >
            <Typography
              fontSize='0.9rem'
            >
              { props.children }
            </Typography>
          </TitleBox>
        </Box>
      </Button>
    </Box>
  );
}

export default KnowledgeListBox;
