import ThreatTimeBlock from './ThreatTimeBlock';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

const ThreatTimeChain = ({ threats, setFocusedThreat }) => {
  return (
    <Box width="max-content" pt={2} pb={1} pl={1} pr={1}>
      <Typography fontSize="1.3rem" fontWeight="bold" pl={0.5} mb={1}>
        攻撃タイムライン
      </Typography>
      <Box
        sx={{
          position: "relative",
          display: "flex",
          gap: 2,
          flexWrap: "nowrap",
          width: "max-content"
        }}
      >
        {threats.map((threat, index) => (
          <ThreatTimeBlock threat={threat} setFocusedThreat={setFocusedThreat} />
        ))}
      </Box>
    </Box>
  );
};

export default ThreatTimeChain;
