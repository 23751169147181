import TitleBox from './TitleBox';

import CheckIcon from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';

const SecondTitleBox = (props) => {
  const { sx, style } = props;
  return (
    <TitleBox
      sx={{ ...sx }}
      style={{ ...style }}
      mb={1}
      Icon={ <CheckIcon sx={{ color: "sky.main" }} style={{ fontSize: "1.3rem" }} /> }
    >
      <Typography
        fontSize='1.1rem'
        fontWeight='bold'
      >
        { props.children }
      </Typography>
    </TitleBox>
  );
};

export default SecondTitleBox;
