import ThreatTimeLeaf from './ThreatTimeLeaf';

import Box from '@mui/material/Box';
import Typography from "@mui/material/Typography";
import Timeline from '@mui/lab/Timeline';
import { timelineItemClasses } from '@mui/lab/TimelineItem';

const ThreatTimeBranch = ({ date, threats, setFocusedThreat }) => {
  threats.at(-1).isLast = true;
  return (
    <Box mb={1}>
      <Typography fontSize="1.3rem" fontWeight="bold" pl={0.5}>
        {date}
      </Typography>
      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            padding: 0,
          },
          marginBottom: 1
        }}
      >
        {threats.map((threat, index) => (
          <ThreatTimeLeaf threat={threat} setFocusedThreat={setFocusedThreat} />
        ))}
      </Timeline>
    </Box>
  );
};

export default ThreatTimeBranch;
