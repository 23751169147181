import TitleBox from '../../../common/TitleBox';

import Button from '@mui/material/Button';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';

import { Box, Typography } from '@mui/material';

const ThreatTimeLeaf = ({ threat, setFocusedThreat }) => {
  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot />
        {threat.isLast ? (
          <></>
        ) : (
          <TimelineConnector />
        )}
      </TimelineSeparator>
      <TimelineContent
        sx={{
          paddingTop: 0,
          paddingRight: 0,
          paddingBottom: 0
        }}
      >
        <Button sx={{ display: "block", textAlign: "left", padding: 0, width: "190px" }} onClick={(e) => setFocusedThreat(threat)}>
          <Box>
            <Typography fontSize="1.2rem" fontWeight="bold">
              {threat.access_clock.split('; ')[1]}
            </Typography>
            <Box pt={1} pb={1}>
              <TitleBox sx={{ mb: 1 }} Icon={<LocationOnIcon sx={{ color: "sky.main" }} /> } >
                {threat.client_ip}
              </TitleBox>
              <TitleBox Icon={<HeartBrokenIcon sx={{ color: "sky.main" }} /> } >
                {threat.attack_method}
              </TitleBox>
            </Box>
          </Box>
        </Button>
      </TimelineContent>
    </TimelineItem>
  );
};

export default ThreatTimeLeaf;
