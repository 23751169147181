import Typography from '@mui/material/Typography';

const Speech = (props) => {
  const { sx, style, mr, mb, ml, mt } = props;

  var body = props.children;
  switch (typeof props.children) {
    case 'object':
      body = props.children.map((x) => typeof x === 'string' ? x.replaceAll('\\ ', '') : x);
      break;
    case 'string':
      body = props.children.replaceAll('\\ ', '');
      break;
  }

  return (
    <Typography
      mr={mr}
      mb={mb}
      ml={ml}
      mt={mt}
      sx={sx}
      style={{ lineHeight: 2.0, ...style }}
    >
      { body }
    </Typography>
  );
};

export default Speech;
