import { useIsMobile } from './isMobile';
import ThreatTimeline from "../threat/comps/timeline/ThreatTimeline";
import HeadTitleBox from '../common/HeadTitleBox';

import { useState } from 'react';
import Box from '@mui/material/Box';

const ThreatTimelineBodyBox = (props) => {
  const isMobile = useIsMobile();
  const [isHoveredTimeline, setIsHoveredTimeline] = useState(false);

  return (
    <Box
      width="100%"
    >
      <Box
        maxWidth="1000px"
        ml="auto"
        mr="auto"
        mb={2}
        pl={1}
        pr={1}
      >
        {!isMobile ? (
          <Box
            sx={{
              display: "flex",
              position: "relative",
              width: "100%",
              height: "100vh"
            }}
          >
            <Box
              sx={{
                position: "absolute",
                left: 0,
                width: "250px",
                height: "100%",
                overflowX: "hidden",
                overflowY: "scroll",
                scrollbarWidth: isHoveredTimeline ? "thin" : "none",
                "&::-webkit-scrollbar": {
                  display: isHoveredTimeline ? "block" : "none",
                  width: isHoveredTimeline ? "1px" : "0px",
                },
              }}
              onMouseEnter={() => setIsHoveredTimeline(true)}
              onMouseLeave={() => setIsHoveredTimeline(false)}
            >
              <ThreatTimeline setFocusedThreat={props.setFocusedThreat} />
            </Box>
            <Box
              sx={{
                marginLeft: "calc(250px + 5%)",
                height: "100%",
                flex: 1,
                overflowY: "scroll",
                scrollbarWidth: "none"
              }}
            >
              {props.children}
            </Box>
          </Box>
        ) : (
          <>
            <Box>
              {props.children}
            </Box>
            <Box
              sx={{
                position: "sticky",
                bottom: 0,
                width: "100%",
                height: "250px",
                overflowX: "scroll"
              }}
            >
              <ThreatTimeline orientation="horizontal" setFocusedThreat={props.setFocusedThreat} />
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};

export default ThreatTimelineBodyBox;
