import TitleBox from '../../../common/TitleBox';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardActionArea from '@mui/material/CardActionArea';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';

const ThreatTimeBlock = ({ threat, setFocusedThreat }) => {
  const [date, clock] = threat.access_clock.split('; ');
  return (
    <Card sx={{ width: "200px", flexShrink: 0 }}>
      <CardActionArea onClick={(e) => setFocusedThreat(threat)}>
        <CardContent>
          <Box mb={1}>
            <Typography fontSize="1.2rem" fontWeight="bold">
              {date}
            </Typography>
            <Typography fontSize="1.1rem" fontWeight="bold">
              {clock}
            </Typography>
          </Box>
          <Divider />
          <Box>
            <TitleBox sx={{ mt: 1, mb: 1 }} Icon={<LocationOnIcon sx={{ color: "sky.main" }} /> } >
              {threat.client_ip}
            </TitleBox>
            <TitleBox Icon={<HeartBrokenIcon sx={{ color: "sky.main" }} /> } >
              {threat.attack_method}
            </TitleBox>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};

export default ThreatTimeBlock;
