import Header from '../../common/Header';
import BodyBox from '../../common/BodyBox';
import HeadTitleBox from '../../common/HeadTitleBox';
import Speech from '../../common/Speech';
import SecondTitleBox from '../../common/SecondTitleBox';
import KnowledgeListBox from '../../common/KnowledgeListBox';

import { Link } from 'react-router-dom';

import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Typography from '@mui/material/Typography';

const ConceptCore = () => {
  return (
    <>
      <Header>
        <Typography style={{ fontSize: "1.3rem", fontWeight: "bold" }}>
          実証実験について
        </Typography>
      </Header>
      <BodyBox>
        <Box id='scope' mb={6}>
          <HeadTitleBox>
            実証実験の目的・狙い
          </HeadTitleBox>
          <Speech mb={4}>
            実証実験では「①サイバー攻撃の抑止」「②第三者に悪用されている機器の特定・救済」の2つを推進していきます。
          </Speech>
          <Box mb={4}>
            <SecondTitleBox>
              ①サイバー攻撃の抑止
            </SecondTitleBox>
            <Speech mb={2}>
              サイバー攻撃は守る側が不利です。\
              私たちは「いつ」「どこから」「どこに」「どのようにして」サイバー攻撃を受けるか分かりません。\
              加えて、攻撃者は追跡されないようにさまざまな工作をしてきます。\
              そのなかで、サイバー攻撃から守る殻を強くするだけでなく、攻撃する側に対抗する手段を持つ気運が高まっています。
            </Speech>
            <Speech>
              この実証実験では、対抗手段の実現に先駆けて、私たちが受けた攻撃的アクセスを「リアルタイムで」特定し、その「アクセス元のIPアドレス」や「攻撃的アクセスの手法」などを公開しています。\
              さらに、みなさんがチェックしアップデートしていける場を目指すことで、サイバー攻撃を抑止する力も大きくなると構想しています。
            </Speech>
          </Box>
          <Box>
            <SecondTitleBox>
              ②第三者に悪用されている機器の特定・救済
            </SecondTitleBox>
            <Speech mb={1}>
              私たちの機器は知らぬ間に第三者に悪用されているかもしれません。\
              特にネットワークに繋がっている機器を抱える企業では、そのリスクは大きくなる傾向にあります。\
              そして第三者に悪用されると、サイバー攻撃の一兵隊として攻撃に加担させられることがあります。
            </Speech>
            <Speech>
              実証実験では、私たちが受けた攻撃的アクセスをリアルタイムで特定し公開していくことで、みなさんの機器のなかで第三者に悪用されている機器を見つけるために活用していけることを構想しています。
            </Speech>
          </Box>
        </Box>
        <Box id='overview' mb={6}>
          <HeadTitleBox>
            実証実験の概要
          </HeadTitleBox>
          <Speech mb={4}>
            上記の目的・狙いを実現していくに向けて、「Webアクセスの見える化によるサイバー攻撃抑止」の実証実験を推進しています。
          </Speech>
          <Box mb={4}>
            <SecondTitleBox>
              抑止の成立要件を満たす設計
            </SecondTitleBox>
            <Speech mb={2}>
              抑止の成立要件は「帰属」「伝達」「信頼性」が十分に機能することと言われています（下記文献参照）。\
              この実証実験のWebサイトでは、この3つの要件が満たされるように設計し実装しています。
            </Speech>
            <Box>
              <KnowledgeListBox
                to='https://dl.ndl.go.jp/view/download/digidepo_9104304_po_20140210.pdf?contentNo=1'
              >
                栗田真広, サイバー攻撃に対する「抑止」の現状 -米国の安全保障政策の事例から-
              </KnowledgeListBox>
            </Box>
          </Box>
          <Box mb={4}>
            <SecondTitleBox>
              IPAの「iLogScanner」を使った攻撃的アクセスの特定
            </SecondTitleBox>
            <Speech mb={2}>
              攻撃的アクセスの特定にあたって、IPAの「iLogScanner」というツールを活用しています。\
              「iLogScanner」はIPAが開発したツールで、Webサイトへのアクセスのなかから「SQLインジェクション」「OSコマンド・インジェクション」「ディレクトリ・トラバーサル」「クロスサイト・スクリプティング」を特定するツールです。
            </Speech>
            <Box>
              <KnowledgeListBox
                to='https://www.ipa.go.jp/security/vuln/ilogscanner/index.html'
              >
                IPA, ウェブサイトの攻撃兆候検出ツール iLogScanner
              </KnowledgeListBox>
            </Box>
          </Box>
        </Box>
        <Box id='usecase' mb={6}>
          <HeadTitleBox>
            実証実験の活用ケース
          </HeadTitleBox>
          <Speech mb={4}>
            「①サイバー攻撃抑止」「②第三者に悪用されている機器の特定・救済」に向けた、当実証実験の活用ケースを紹介します。
          </Speech>
          <Box mb={4}>
            <SecondTitleBox>
              自身の機器の設定を確かめる
            </SecondTitleBox>
            <Speech mb={2}>
              サイバー脅威は意外と身近にあります。\
              私たちの規模のWebサイトでも観測を始めてから2, 3年で3000件近くの攻撃的なアクセスを受けています。\
              そして、その具体例として、私たちが実際に受けた攻撃的アクセスを公開しています。
            </Speech>
            <Speech mb={2}>
              攻撃的アクセスを見て、彼らのIPアドレスやその手法に注目してみてください。\
              攻撃的なアクセス者は、ツールを活用して同様のアクセスを場所を変えて繰り返すケースがあります。\
              また、同じIPアドレスで、手法やターゲットを変えてくるかもしれません。\
              これらへの対策をすることで、サイバーセキュリティのレベルを上げていくことができます。
            </Speech>
            <Box>
              <KnowledgeListBox
                to='https://www.ipa.go.jp/security/vuln/websecurity/sql.html'
              >
                IPA, SQLインジェクションの脅威と対策
              </KnowledgeListBox>
              <KnowledgeListBox
                to='https://www.ipa.go.jp/security/vuln/websecurity/os-command.html'
              >
                IPA, OSコマンド・インジェクションの脅威と対策
              </KnowledgeListBox>
              <KnowledgeListBox
                to='https://www.ipa.go.jp/security/vuln/websecurity/parameter.html'
              >
                IPA, ディレクトリ・トラバーサルの脅威と対策
              </KnowledgeListBox>
              <KnowledgeListBox
                to='https://www.ipa.go.jp/security/vuln/websecurity/cross-site-scripting.html'
              >
                IPA, クロスサイト・スクリプティングの脅威と対策
              </KnowledgeListBox>
              <KnowledgeListBox
                to='https://www.ntt.com/business/services/network/internet-connect/ocn-business/bocn/knowledge/archive_09.html'
              >
                NTTコミュニケーションズ, ファイアウォールとは？設定方法とUTMなどの関連セキュリティ対策
              </KnowledgeListBox>
              <KnowledgeListBox
                to='https://www.ntt.com/bizon/glossary/e-i/ips-ids.html'
              >
                NTTコミュニケーションズ, IPS（Intrusion Prevention System）/IDS（Intrusion Detection System）とは
              </KnowledgeListBox>
              <KnowledgeListBox
                to='https://www.nri-secure.co.jp/glossary/waf'
              >
                NRIセキュア, WAF（Web Application Firewall）｜セキュリティ用語解説
              </KnowledgeListBox>
            </Box>
          </Box>
          <Box>
            <SecondTitleBox>
              自身の機器が第三者に悪用されていないか調べる
            </SecondTitleBox>
            <Speech mb={2}>
              私たちが公開している攻撃的アクセスの情報は、確かに私たちに攻撃的アクセスをしてきた加害者です。\
              もし、みなさんのネットワーク機器が意図せず含まれていた場合、第三者に悪用されている可能性があります。
            </Speech>
            <Speech mb={2}>
              そして第三者は、みなさんの機器を悪用するために、他者の機器を悪用しているケースもあります。\
              そのため、一件の判明で連鎖的に攻撃者に近づけることもあります。\
              定期的に確認したり、日ごろから対策したりしてみてください。
            </Speech>
            <Box>
              <KnowledgeListBox
                to='https://www.nri-secure.co.jp/blog/springboard_attack'
              >
                NRIセキュア, WEBサイトの踏み台攻撃とは？対策10選を予防・封じ込め・追跡の観点から解説
              </KnowledgeListBox>
              <KnowledgeListBox
                to='https://www.gmo.jp/security/cybersecurity/penetration-testing/blog/stepping-stone-attack/'
              >
                GMOインターネットグループ, 踏み台攻撃とは｜主な手口や被害事例・企業が行うべき対策を解説
              </KnowledgeListBox>
              <KnowledgeListBox
                to='https://form.jpcert.or.jp/'
              >
                JPCERT/CC, インシデントの報告
              </KnowledgeListBox>
              <KnowledgeListBox
                to='https://www.ipa.go.jp/security/todokede/crack-virus/about.html'
              >
                IPA, コンピュータウイルス・不正アクセスに関する届出について
              </KnowledgeListBox>
            </Box>
          </Box>
        </Box>
      </BodyBox>
    </>
  );
};

export default ConceptCore;
