import { getThreats } from '../../util/fetch';
import { TitleBox } from '../../../common/TitleBox';
import ThreatTimeTree from './ThreatTimeTree';
import ThreatTimeChain from './ThreatTimeChain';

import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import CircularProgress from '@mui/material/CircularProgress';
import { io } from 'socket.io-client';

const ThreatTimeline = ({ orientation="vertical", setFocusedThreat }) => {
  const [latestThreat, setLatestThreat] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasNewThreat, sethasNewThreat] = useState(false);

  useEffect(() => {
    (async () => {
      const d = await getThreats({limit: 10});
      setLatestThreat(d);
      setFocusedThreat(d[0]);

      setIsLoading(false);
      sethasNewThreat(true);
    })();

    const socket = io('http://172.18.0.46');
    socket.on('LatestThreat', (d) => {
      sethasNewThreat(latestThreat[0] != d[0]);
      setIsLoading(false);
      setLatestThreat(d);
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Box
          sx={{
            width: "max-content",
            height: "max-content",
            backgroundColor: "#f8f8f8"
          }}
          style={{
            opacity: hasNewThreat ? 1 : 0,
            transition: "opacity 3s ease"
          }}
        >
          {orientation == "vertical" ? (
            <Box width="250px" height="100%">
              <ThreatTimeTree threats={latestThreat} setFocusedThreat={setFocusedThreat} />
            </Box>
          ) : orientation == "horizontal" ? (
            <Box width="max-content" height="100%">
              <ThreatTimeChain threats={latestThreat} setFocusedThreat={setFocusedThreat} />
            </Box>
          ) : (
            <></>
          )}
        </Box>
      )}
    </>
  );
};

export default ThreatTimeline;
