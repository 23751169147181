import Typography from '@mui/material/Typography';
import ListItemIcon from '@mui/material/ListItemIcon';
import CircleIcon from '@mui/icons-material/Circle';

const ListSpeech = (props) => {
  const { sx, style } = props;

  return (
    <>
      <ListItemIcon sx={{ minWidth: "30px" }}>
        <CircleIcon sx={{ fontSize: "medium", color: "sky.main" }} />
      </ListItemIcon>
      <Typography
        sx={sx}
        style={style}
      >
        {props.children}
      </Typography>
    </>
  );
};

export default ListSpeech;
