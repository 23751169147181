// import SpringModal from "../../../util/modal/SpringModal";
import ResponsiveModal from "../../../common/ResponsiveModal";
import { useIsMobile } from "../../../common/isMobile";

import { useEffect, useState, useCallback, useRef } from "react";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from "@mui/material/InputAdornment";
import Grid from '@mui/material/Grid2';
import CloseIcon from '@mui/icons-material/Close';
import CameraIcon from '@mui/icons-material/Camera';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import SortIcon from '@mui/icons-material/Sort';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import PublishIcon from '@mui/icons-material/Publish';

const Method = [
  {
    value: 'all',
    surface: 'すべて'
  },
  {
    value: 'sql-ij',
    surface: 'SQLインジェクション'
  },
  {
    value: 'oscmd-ij',
    surface: 'OSコマンドインジェクション'
  },
  {
    value: 'dt',
    surface: 'ディレクトリトラバーサル'
  },
  {
    value: 'xss',
    surface: 'クロスサイトスクリプティング'
  },
  {
    value: 'other',
    surface: 'その他'
  }
];

const Order = [
  {
    value: 'access_clock',
    surface: '日時'
  },
  {
    value: 'client_ip',
    surface: 'IP アドレス'
  }
];

var save = {start: '', end: '', ip: '', method: 'all', orderKey: 'access_clock', doASC: false, limit: ''};

const ThreatSearchModal = ({ open, handleClose, updateThreats }) => {
  // const y1 = useRef();
  // const m1 = useRef();
  // const d1 = useRef();
  // const y2 = useRef();
  // const m2 = useRef();
  // const d2 = useRef();
  const start = useRef();
  const end = useRef();
  const ip = useRef();
  const method = useRef();
  const orderKey = useRef();
  const doASC = useRef();
  const limit = useRef();

  const executeSearch = () => {
    const [y1, m1, d1] = start.current.value.split("/");
    const [y2, m2, d2] = end.current.value.split("/");
    var params = {
      y1: y1,
      m1: m1,
      d1: d1,
      y2: y2,
      m2: m2,
      d2: d2,
      ip: ip.current.value,
      method: method.current.value,
      order: orderKey.current.value,
      asc: doASC.current.checked ? 'on': 'off',
      limit: limit.current.value
    };

    save.start = start.current.value;
    save.end = end.current.value;
    save.ip = ip.current.value;
    save.method = method.current.value;
    save.order = orderKey.current.value;
    save.doASC = doASC.current.checked;
    save.limit = limit.current.value;

    handleClose();
    updateThreats(params);
  };

  const isMobile = useIsMobile();
  const ModalContent = () => {
    return (
      <Box
        sx={{ width: "100%", height: "700px" }}
      >
        <Box
          sx={{ width: "100%" }}
        >
          <Box
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <IconButton onClick={handleClose} >
              <CloseIcon style={{ fontSize: "2rem" }} />
            </IconButton>
          </Box>
        </Box>
        <Box
          mb={2}
        >
          <Box
            mb={2}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box
              mr={1}
            >
              <CameraIcon sx={{ color: "sky.main" }} />
            </Box>
            <Typography
              variant="h5"
              component="h5"
              fontWeight="bold"
            >
              条件を指定して絞る
            </Typography>
          </Box>
          <Box>
            <Typography
              mb={2}
              style={{ lineHeight: 1.8 }}
            >
              表示する攻撃的アクセスの一覧を、条件から絞ることができます。
            </Typography>
          </Box>
        </Box>
        <Box
          // sx={{ display: "grid", gridTemplateColumns: "repeat(auto-fit, 260px)", justifyContent: "center", gridGap: "10px" }}
          sx={{ display: "flex", flexWrap: "wrap", gap: "2%" }}
        >
          <Box
            mb={2}
            width='100%'
          >
            <Box
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Box
                mr={1}
              >
                <AccessTimeIcon sx={{ fontSize: "1.3rem", color: "sky.main" }} />
              </Box>
              <Typography
                fontSize='1.2rem'
                fontWeight='bold'
              >
                期間
              </Typography>
            </Box>
            <Box>
              <Typography
                mb={1}
                fontSize='0.7rem'
              >
                Ex. 2023/10/18
              </Typography>
              <Box
                sx={{ display: "flex", flexWrap: "wrap", gap: "2%" }}
              >
                <TextField
                  label='Start'
                  inputRef={start}
                  defaultValue={save.start}
                  sx={{ minWidth: "200px", width: "49%", marginBottom: isMobile ? 1 : 0 }}
                />
                <TextField
                  label="End"
                  inputRef={end}
                  defaultValue={save.end}
                  sx={{ minWidth: "200px", width: "49%" }}
                />
              </Box>
            </Box>
          </Box>
          <Box
            width='49%'
            minWidth='200px'
            mb={2}
          >
            <Box
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Box
                mr={1}
              >
                <LocationOnIcon sx={{ fontSize: "1.3rem", color: "sky.main" }} />
              </Box>
              <Typography
                fontSize='1.2rem'
                fontWeight='bold'
              >
                IPアドレス
              </Typography>
            </Box>
            <Box>
              <Typography
                mb={1}
                fontSize='0.7rem'
              >
                Ex. 192.168.1.1
              </Typography>
              <TextField
                sx={{ minWidth: "100px", width: "100%" }}
                label='IP Address'
                inputRef={ip}
                defaultValue={save.ip}
              />
            </Box>
          </Box>
          <Box
            width='49%'
            minWidth='200px'
            mb={2}
          >
            <Box
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Box
                mr={1}
              >
                <HeartBrokenIcon sx={{ fontSize: "1.3rem", color: "sky.main" }} />
              </Box>
              <Typography
                fontSize='1.2rem'
                fontWeight='bold'
              >
                攻撃的アクセスの種類
              </Typography>
            </Box>
            <Box>
              <Typography
                mb={1}
                fontSize='0.7rem'
              >
                Ex. SQLインジェクション
              </Typography>
              <TextField
                sx={{ minWidth: "100px", width: "100%" }}
                select
                label='Adversarial Access Type'
                inputRef={method}
                defaultValue={save.method}
              >
                {Method.map(({ value, surface }) => (
                  <MenuItem key={`access-type-${value}`} value={value}>
                    {surface}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Box>
          <Box
            width='49%'
            minWidth='200px'
            mb={2}
          >
            <Box
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Box
                mr={1}
              >
                <SortIcon sx={{ fontSize: "1.3rem", color: "sky.main" }} />
              </Box>
              <Typography
                fontSize='1.2rem'
                fontWeight='bold'
              >
                キー列
              </Typography>
            </Box>
            <Box>
              <Typography
                fontSize='0.7rem'
              >
                並べ替えの際に何を基準にするか
              </Typography>
              <Typography
                mb={1}
                fontSize='0.7rem'
              >
                Ex. 日時
              </Typography>
              <TextField
                sx={{ minWidth: "100px", width: "100%" }}
                select
                label='Order Key'
                inputRef={orderKey}
                defaultValue={save.orderKey}
              >
                {Order.map(({ value, surface }) => (
                  <MenuItem key={`order-${value}`} value={value}>
                    {surface}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Box>
          <Box
            width='49%'
            minWidth='200px'
            mb={2}
          >
            <Box
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Box
                mr={1}
              >
                <ArrowUpwardIcon sx={{ fontSize: "1.3rem", color: "sky.main" }} />
              </Box>
              <Typography
                fontSize='1.2rem'
                fontWeight='bold'
              >
                並び順
              </Typography>
            </Box>
            <Box>
              <Typography
                fontSize="0.7rem"
              >
                昇順にするか(デフォルトは降順です。)
              </Typography>
              <Typography
                mb={1}
                fontSize='0.7rem'
              >
                Ex. 日時
              </Typography>
              <TextField
                sx={{ minWidth: "100px", width: "100%" }}
                label='ASC?'
                defaultValue='昇順にする'
                InputProps={{
                  startAdornment: (
                    <InputAdornment position='start'>
                      <Checkbox inputRef={doASC} defaultChecked={save.doASC} />
                    </InputAdornment>
                  ),
                  readOnly: true
                }}
              />
            </Box>
          </Box>
          <Box
            width='49%'
            minWidth='200px'
            mb={2}
          >
            <Box
              sx={{ display: "flex", alignItems: "center" }}
            >
              <Box
                mr={1}
              >
                <PublishIcon sx={{ fontSize: "1.3rem", color: "sky.main" }} />
              </Box>
              <Typography
                fontSize='1.2rem'
                fontWeight='bold'
              >
                表示上限
              </Typography>
            </Box>
            <Box>
              <Typography
                fontSize='0.7rem'
              >
                (デフォルトは100件です。)
              </Typography>
              <Typography
                mb={1}
                fontSize='0.7rem'
              >
                Ex. 100
              </Typography>
              <TextField
                sx={{ minWidth: "100px", width: "100%" }}
                label='Display Limit'
                inputRef={limit}
                defaultValue={save.limit}
              />
            </Box>
          </Box>
          <Box
            width='49%'
            minWidth='200px'
            mb={2}
          >
            <Box
              sx={{ display: "flex", flexWrap: "wrap", justifyContent: "flex-end", alignContent: "flex-end", width: "100%", height: "100%", gap: "2%" }}
            >
              <Button variant='outlined' sx={{ width: "30%", minWidth: "75px", marginBottom: isMobile ? 1 : 0 }} onClick={handleClose} >やめる</Button>
              <Button variant='contained' sx={{ width: "68%", minWidth: "178px" }} onClick={executeSearch} >この条件で絞り込む</Button>
            </Box>
          </Box>
        </Box>
        {/* <Typography component='div'>
          <Box fontSize='h5.fontSize' fontWeight='fontWeightBold'>
          </Box>
        </Typography>
        <Divider />
        <Box component='form' sx={{ flexGrow: 1, mt: 1 }}>
          <Grid container spacing={2}>
            <Grid xs={12}>
              <Typography fontSize='h6.fontSize' fontWeight='fontWeightBold'>
                期間
              </Typography>
              <Typography fontSize={14}>
                Year1/Month1/Day1 - Year2/Month2/Day2
              </Typography>
            </Grid>
            <Grid xs={4}>
              <TextField
                label='Year1'
                variant='filled'
                inputRef={y1}
              />
            </Grid>
            <Grid xs={4}>
              <TextField
                label='Month1'
                variant='filled'
                inputRef={m1}
              />
            </Grid>
            <Grid xs={4}>
              <TextField
                label='Day1'
                variant='filled'
                inputRef={d1}
              />
            </Grid>
            <Grid xs={4}>
              <TextField
                label='Year2'
                variant='filled'
                inputRef={y2}
              />
            </Grid>
            <Grid xs={4}>
              <TextField
                label='Month2'
                variant='filled'
                inputRef={m2}
              />
            </Grid>
            <Grid xs={4}>
              <TextField
                label='Day2'
                variant='filled'
                inputRef={d2}
              />
            </Grid>
            <Grid container xs={6}>
              <Grid xs={12}>
                <Typography fontSize='h6.fontSize' fontWeight='fontWeightBold'>
                  IP アドレス
                </Typography>
                <Typography fontSize={14}>
                  IP-Address
                </Typography>
              </Grid>
              <Grid xs={12}>
                <TextField
                  sx={{ width: '85%' }}
                  label='IP-Address'
                  variant='filled'
                  inputRef={ip}
                />
              </Grid>
            </Grid>
            <Grid container xs={6}>
              <Grid xs={12}>
                <Typography fontSize='h6.fontSize' fontWeight='fontWeightBold'>
                  アクセスの種類
                </Typography>
                <Typography fontSize={14}>
                  Access-Type
                </Typography>
              </Grid>
              <Grid xs={12}>
                <TextField
                  sx={{ width: '85%' }}
                  select
                  label='Access-Type'
                  defaultValue='all'
                  variant='filled'
                  inputRef={method}
                >
                  {Method.map(({ value, surface }) => (
                    <MenuItem key={`access-type-${value}`} value={value}>
                      {surface}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid container xs={6}>
              <Grid xs={12}>
                <Typography fontSize='h6.fontSize' fontWeight='fontWeightBold'>
                  並び順
                </Typography>
                <Typography fontSize={14}>
                  Order-Key
                </Typography>
              </Grid>
              <Grid xs={12}>
                <TextField
                  sx={{ width: '85%' }}
                  select
                  label='Order-Key'
                  defaultValue='access_clock'
                  variant='filled'
                  inputRef={order}
                >
                  {Order.map(({ value, surface }) => (
                    <MenuItem key={`order-${value}`} value={value}>
                      {surface}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
            <Grid container xs={6}>
              <Grid xs={12}>
                <Typography>
                  <Typography component='span' fontSize='h6.fontSize' fontWeight='fontWeightBold'>
                    昇順
                  </Typography>
                  <Typography component='span' fontSize={12} ml={1}>
                    デフォルトは降順
                  </Typography>                
                </Typography>
                <Typography fontSize={14}>
                  ASC? (default: DESC)
                </Typography>
              </Grid>
              <Grid xs={12}>
                <TextField
                  sx={{ width: '85%' }}
                  label='ASC?'
                  defaultValue='昇順にする'
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <Checkbox inputRef={asc}/>
                      </InputAdornment>
                    ),
                    readOnly: true
                  }}
                  variant='filled'
                />
              </Grid>
            </Grid>
            <Grid container xs={6}>
              <Grid xs={12}>
                <Typography fontSize='h6.fontSize' fontWeight='fontWeightBold'>
                  表示上限
                </Typography>
                <Typography fontSize={14}>
                  Limit
                </Typography>
              </Grid>
              <Grid xs={12}>
                <TextField
                  sx={{ width: '85%' }}
                  label='Limit'
                  variant='filled'
                  inputRef={limit}
                />
              </Grid>
            </Grid>
            <Grid container xs={6} justifyContent='flex-end' alignContent='flex-end' spacing={2}>
              <Grid xs={12}>
                <Box width='85%'>
                  <Button variant='contained' onClick={executeSearch} fullWidth>この条件で絞り込む</Button>
                </Box>
              </Grid>
              <Grid xs={12}>
                <Box width='85%'>
                  <Button variant='outlined' onClick={handleClose} color='info' fullWidth>やめる</Button>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Box> */}
      </Box>
    );
  };

  return (
    <ResponsiveModal
      open={open}
      handleClose={handleClose}
      ModalContent={ModalContent}
    />
  );
};

export default ThreatSearchModal;
